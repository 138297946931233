<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>웨딩 플래너 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL" v-if="adminType != 'PLANNER'">
          <p class="bold">
            플래너명
            <span>*</span>
          </p>
          <div class="plannerInput">
            <p @click="handleModal">{{ plannerName }}</p>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            플래너타입
            <span>*</span>
          </p>
          <div class="labelBox">
            <label>
              <input type="radio" value="PLANNER" v-model="plannerType" />
              웨딩 플래너
            </label>
            <label>
              <input type="radio" value="DIRECTOR" v-model="plannerType" />
              웨딩 디렉터
            </label>
            <label>
              <input type="radio" value="TOURPLANNER" v-model="plannerType" />
              투어 플래너
            </label>
          </div>
        </div>

        <div class="flexL" v-if="adminType != 'PLANNER'">
          <p class="bold">
            상태
            <span>*</span>
          </p>
          <el-select
            v-model="status"
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="계약중" label="계약중" value="COMPLETE"></el-option>
            <el-option
              key="계약종료"
              label="계약종료"
              value="FINISHED"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">이벤트라벨</p>
          <el-select v-model="label" placeholder="선택" class="searchCondition">
            <el-option key="미선택" label="미선택" value=""></el-option>
            <el-option key="EVENT" label="EVENT" value="EVENT"></el-option>
            <el-option key="HOT" label="HOT" value="HOT"></el-option>
            <el-option key="NEW" label="NEW" value="NEW"></el-option>
            <el-option key="BEST" label="BEST" value="BEST"></el-option>
            <el-option key="COUPON" label="COUPON" value="COUPON"></el-option>
            <el-option key="추천" label="추천" value="추천"></el-option>
            <el-option
              key="후기이벤트"
              label="후기이벤트"
              value="후기이벤트"
            ></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            소속
            <span>*</span>
          </p>
          <input type="text" v-model="agency" />
        </div>
        <div class="flexL">
          <p class="bold">주소</p>
          <input type="text" v-model="address" />
        </div>
        <div class="flexL">
          <p class="bold">주차</p>
          <input type="text" v-model="parking" />
        </div>
        <div class="flexL">
          <p class="bold">연락처 <span>*</span></p>
          <input type="text" v-model="mobileNo" />
        </div>
        <div class="flexL">
          <p class="bold">
            활동지역
            <span>*</span>
          </p>
          <el-select
            v-model="region"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option
              key="광주광역시"
              label="광주광역시"
              value="광주광역시"
            ></el-option>
            <el-option
              key="여수/순천/광양시"
              label="여수/순천/광양시"
              value="여수/순천/광양시"
            ></el-option>
            <el-option key="목포시" label="목포시" value="목포시"></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            휴무일
            <span>*</span>
          </p>
          <el-select
            v-model="holidays"
            multiple
            placeholder="선택"
            class="searchCondition"
          >
            <el-option key="월요일" label="월요일" value="월요일"></el-option>
            <el-option key="화요일" label="화요일" value="화요일"></el-option>
            <el-option key="수요일" label="수요일" value="수요일"></el-option>
            <el-option key="목요일" label="목요일" value="목요일"></el-option>
            <el-option key="금요일" label="금요일" value="금요일"></el-option>
            <el-option key="토요일" label="토요일" value="토요일"></el-option>
            <el-option key="일요일" label="일요일" value="일요일"></el-option>
          </el-select>
        </div>
        <div class="flexL">
          <p class="bold">
            활동시간
            <span>*</span>
          </p>
          <input type="text" v-model="workTime" />
        </div>
        <div class="flexL">
          <p class="bold">
            동행 여부
            <span>*</span>
          </p>
          <div class="labelBox">
            <label>
              <input type="radio" value="true" v-model="followup" />
              동행
            </label>
            <label>
              <input type="radio" value="false" v-model="followup" />
              비동행
            </label>
          </div>
        </div>
        <div class="flexL">
          <p class="bold">
            상담비
            <span>*</span>
          </p>
          <input type="text" v-model="fee" />
        </div>
        <div class="flexL">
          <p class="bold">유튜브 url</p>
          <input
            type="text"
            v-model="youtube"
            placeholder="유튜브에서 공유 / 퍼가기에 iframe 소스를 입력해 주세요."
          />
        </div>
        <div class="flexL">
          <p class="bold">인스타그램</p>
          <input
            type="text"
            v-model="instagram"
            placeholder="사용자이름(계정)을 입력해 주세요."
          />
        </div>
        <div class="flexL multiple">
          <p class="bold">
            태그
            <span>*</span>
          </p>
          <ol>
            <li>
              <span>①</span>
              <input type="text" v-model="tag1" />
            </li>
            <li>
              <span>②</span>
              <input type="text" v-model="tag2" />
            </li>
            <li>
              <span>③</span>
              <input type="text" v-model="tag3" />
            </li>
          </ol>
        </div>
        <div class="flexL">
          <p class="bold">투어 쿠폰</p>
          <input
            type="text"
            v-model="coupons"
            placeholder="(쿠폰종류별로 ',' 로 구분해주세요. 예)1.상품권, 2.영화표)"
          />
        </div>
        <div class="flexL double">
          <p class="bold">
            신랑신부에게 한마디
            <span>*</span>
          </p>
          <textarea v-model="comment" />
        </div>
        <div v-if="editMode" class="flexL">
          <p class="bold">
            포트폴리오 정보
            <span>*</span>
          </p>
          <button class="register" @click="handlePortfolioModal('')">
            등록하기
          </button>
        </div>
        <div class="flexL">
          <p class="bold">상품 정보</p>
          <button class="register" @click="handleCollectionModal(null, null)">
            등록하기
          </button>
        </div>
        <div class="flexL">
          <p class="bold">
            대표사진
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li v-if="images">
                <img :src="images" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(images)">
                  <img
                    src="@/assets/images/icon_svg/ic_delete_wh.svg"
                    alt="icon"
                  />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="image"
                  @change="sendFile()"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            >
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <router-link
          to="/admin/plannermanage"
          v-if="adminType != 'PLANNER' && adminType != 'ADVISOR'"
          >목록</router-link
        >
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
    <div class="box one">
      <div class="tableList">
        <h2 class="bold">포트폴리오 리스트</h2>
        <table v-if="portfolioList.length > 0">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 30%" />
            <col style="width: 45%" />
            <col style="width: 15%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>포트폴리오 이름</th>
            <th>연관 브랜드</th>
            <th class="right">가격</th>
          </tr>
          <tr v-for="(data, i) in portfolioList" :key="i">
            <td>{{ currentPage == 1 ? "" : currentPage }}{{ i + 1 }}</td>
            <td @click="handlePortfolioModal(data._id)">
              <em>{{ data.title }}</em>
            </td>
            <td>
              <span class="categoryTag" v-if="data.weddingPlace">
                {{ data.weddingPlace.title }}
              </span>
              <span
                v-for="(item, j) in data.collections"
                :key="j"
                class="categoryTag"
                >{{ item.title }}</span
              >
            </td>
            <td class="right">{{ numberFormat(data.fee) }}원</td>
          </tr>
        </table>
        <div class="pagination" v-if="portfolioList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>
    <div class="box one">
      <div class="tableList">
        <h2 class="bold">상품 리스트</h2>
        <table v-if="products.length > 0">
          <colgroup>
            <col style="width: 8%" />
            <col style="width: 12%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 18%" />
            <col style="width: 14%" />
          </colgroup>
          <tr>
            <th>번호</th>
            <th>상품정보 제목</th>
            <th>혜택 제목</th>
            <th>혜택 적용 내용</th>
            <th>혜택 적용 기간</th>
            <th class="center">수정/삭제</th>
          </tr>
          <tr v-for="(data, i) in products" :key="i">
            <td>{{ i + 1 }}</td>
            <td @click="handleCollectionModal(data, i)">
              <em>{{ data.title }}</em>
            </td>
            <td>{{ data.service }}</td>
            <td>{{ data.svcContents }}</td>
            <td>{{ formatApplyDate(data.applyDate) }}</td>
            <td class="center">
              <button class="table delete" @click="itemDelete(i, 'product')">
                삭제
              </button>
            </td>
          </tr>
        </table>
        <div class="nodata" v-else>
          <p>데이터가 없습니다.</p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible == 'portfolio'">
        <PortfolioModal
          :plannerId="plannerId"
          :portfolioId="portfolioId"
          v-if="portfolioVisible"
          @close="close"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>

    <transition name="fade">
      <div class="modal" v-if="modalVisible == 'planners'">
        <div class="modal_bg" @click="userModalReset"></div>
        <div class="modal_body">
          <div class="modal_header">
            <p>검색</p>
            <button @click="userModalReset">
              <span class="material-icons-round">clear</span>
            </button>
          </div>
          <div class="modal_content">
            <div class="searchWrap">
              <input
                type="text"
                placeholder="이름을 입력해 주세요"
                v-model="searchWord"
                @keyup.enter="search"
              />
              <button @click="search">
                <span class="material-icons-round">search</span>
              </button>
            </div>
            <div class="tableBox">
              <table v-if="searchResults.length > 0">
                <tr>
                  <th>이름</th>
                </tr>
                <tr
                  v-for="(data, i) in searchResults"
                  :key="i"
                  @click="userSelect(data)"
                >
                  <td>{{ data.username }}</td>
                </tr>
              </table>
              <div
                class="nodata"
                v-else-if="searchResults.length == 0 && searchWord != ''"
              >
                <p>검색결과가 없습니다.</p>
              </div>
              <div class="pagination" v-if="searchResults.length > 0">
                <el-pagination
                  layout="prev, pager, next"
                  :total="modalTotal"
                  :page-size="10"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="dim" v-if="modalVisible == 'collection'">
        <CollectionModal3
          :plannerId="plannerId"
          :select="select"
          @close="close"
          @updateData="updateData"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import { uploadFile, deleteFile, deletePostFile } from "@/api/file";
import {
  createPlanner,
  fetchPlannerDetail,
  upadatePlanner,
  fetchPortfolioList,
  fetchUserList,
  findPlannerByUserId,
} from "@/api/admin";
import { format } from "@/mixins/format";
import { mapState } from "vuex";
import PortfolioModal from "@/components/pop/PortfolioModal";
import CollectionModal3 from "@/components/pop/CollectionModal3";

export default {
  mixins: [format],
  components: { PortfolioModal, CollectionModal3 },

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: "",
      portfolioVisible: false,
      plannerId: "",
      portfolioId: "",
      plannerType: "",
      label: "",
      plannerName: "",
      region: [],
      select: null,
      index: null,
      products: [],
      agency: "",
      address: "",
      parking: "",
      mobileNo: "",
      tag1: "",
      tag2: "",
      tag3: "",
      holidays: [],
      workTime: "",
      followup: null,
      status: "",
      fee: "",
      comment: "",
      file: "",
      images: "",
      youtube: "",
      instagram: "",
      coupons: "",
      portfolioList: [],
      portfolio: [],
      currentPage: 1,
      total: 0,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      adminType: "",
    };
  },
  watch: {
    fee: function (newValue) {
      const result = newValue
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.fee = result;
    },
  },
  computed: {
    ...mapState(["_id", "username"]),
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5-1");
  },
  mounted() {
    if (this.$route.query.id != null) {
      this.editMode = true;
      this.plannerId = this.$route.query.id;
      this.getPlannerDetail();
      this.getPortfolio();
    } else {
      if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
        this.plannerName = this.username;
        this.user = this._id;
        findPlannerByUserId().then((res) => {
          if (res.data.status == 200) {
            if (res.data.data != null) {
              this.user = res.data.data.user;

              this.editMode = true;
              this.plannerId = res.data.data._id;
              this.getPlannerDetail();
              this.getPortfolio();
            }
          }
        });
      }
    }
  },
  methods: {
    handlePortfolioModal(id) {
      if (id) {
        this.portfolioId = id;
      }
      this.modalVisible = "portfolio";
      this.portfolioVisible = true;
    },
    handleCollectionModal(data, index) {
      if (data != null && index != null) {
        this.select = data;
        this.index = index;
      }
      this.type = "product";
      this.modalVisible = "collection";
      this.collectionVisible = true;
    },
    itemDelete(index, type) {
      if (type == "product") {
        this.products.splice(index, 1);
      }
    },
    updateData(data) {
      if (this.type == "product") {
        if (this.index != null) {
          this.products.splice(this.index, 1, data);
        } else {
          this.products.push(data);
        }
      }
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    close() {
      this.index = null;
      this.select = null;
      this.modalVisible = false;
      this.portfolioVisible = false;
    },
    formatApplyDate(dates) {
      if (!dates || dates.length !== 2) return "";
      const startDate = moment(dates[0]).format("YYYY.MM.DD");
      const endDate = moment(dates[1]).format("YYYY.MM.DD");
      return `${startDate} ~ ${endDate}`;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPortfolio();
    },
    getPortfolio() {
      let params = {
        plannerId: this.plannerId,
        page: this.currentPage,
      };
      fetchPortfolioList(params).then((res) => {
        if (res.data.status == 200) {
          this.portfolioList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.page;

          this.portfolioList.forEach((item) => this.portfolio.push(item._id));
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    getPlannerDetail() {
      fetchPlannerDetail(this.plannerId).then((res) => {
        if (res.data.status == 200) {
          this.plannerType = res.data.data.plannerType;
          this.label = res.data.data.label;
          this.plannerName = res.data.data.plannerName;
          this.region = res.data.data.region;
          this.agency = res.data.data.agency;
          this.address = res.data.data.address;
          this.parking = res.data.data.parking;
          this.mobileNo = res.data.data.mobileNo;
          this.tag1 = res.data.data.tag[0];
          this.tag2 = res.data.data.tag[1];
          this.tag3 = res.data.data.tag[2];
          this.holidays = res.data.data.holidays;
          this.workTime = res.data.data.workTime;
          this.followup = res.data.data.followup;
          this.fee = res.data.data.fee;
          this.comment = res.data.data.comment;
          this.images = res.data.data.images;
          this.youtube = res.data.data.youtube;
          this.instagram = res.data.data.instagram;
          this.user = res.data.data.user;
          this.status = res.data.data.status;
          this.coupons = res.data.data.coupons;
          this.products = res.data.data.products;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    sendFile() {
      if (this.images) {
        this.$alert("삭제 후 업로드 해주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      if (this.file == undefined) {
        return;
      }
      this.file = this.$refs.image.files[0];
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.images = res.data.url;
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        if (this.plannerId) {
          deletePostFile(this.plannerId, url).then((res) => {
            if (res.data.status == 200) {
              this.images = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        } else {
          deleteFile(url).then((res) => {
            if (res.data.status == 200) {
              this.images = "";
            } else {
              this.$alert(res.data.message, {
                confirmButtonText: "확인",
                center: true,
              });
            }
          });
        }
      });
    },

    submit() {
      if (this.plannerType == "") {
        this.$alert("플래너타입을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.user == "") {
        this.$alert("플래너를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.region == "") {
        this.$alert("활동지역을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.agency == "") {
        this.$alert("소속을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.mobileNo == "") {
        this.$alert("연락처를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.holidays == "") {
        this.$alert("휴무일을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.workTime == "") {
        this.$alert("활동시간을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.followup == null) {
        this.$alert("동행 여부를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.fee == "") {
        this.$alert("상담비를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.tag1 == "" && this.tag2 == "" && this.tag3 == "") {
        this.$alert("태그를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.comment == "") {
        this.$alert("신랑신부에게 한마디를 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.images == "") {
        this.$alert("대표사진을 등록해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        plannerId: this.plannerId,
        plannerType: this.plannerType,
        label: this.label,
        user: this.user,
        plannerName: this.plannerName,
        region: this.region,
        agency: this.agency,
        tag: [this.tag1, this.tag2, this.tag3],
        holidays: this.holidays,
        workTime: this.workTime,
        followup: this.followup,
        fee: this.uncomma(this.fee),
        comment: this.comment,
        images: this.images,
        youtube: this.youtube,
        instagram: this.instagram,
        portfolio: this.portfolio,
        status: this.status,
        address: this.address,
        parking: this.parking,
        mobileNo: this.mobileNo,
        coupons: this.coupons,
        products: this.products,
      };
      if (this.editMode) {
        upadatePlanner(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("플래너 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
                this.editMode = true;
                this.$store.dispatch("getAccountInfo");
              } else {
                this.$router.push("/admin/plannermanage");
              }
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createPlanner(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("플래너 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              if (this.adminType == "PLANNER" || this.adminType == "ADVISOR") {
                this.editMode = true;
                this.$store.dispatch("getAccountInfo");
              } else {
                this.$router.push("/admin/plannermanage");
              }
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },

    handleModal() {
      if (this.adminType == "SUPER") {
        this.modalVisible = "planners";
        this.getUserList();
      }
    },
    getUserList() {
      let params = {
        page: this.modalPage,
        inputMode: "username",
        searchWord: this.searchWord,
        userType: "planner",
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchResults = res.data.users;
          this.modalTotal = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.modalPage = res.data.page;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    search() {
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.getUserList();
    },
    userModalReset() {
      this.searchWord = "";
      this.modalPage = 1;
      this.searchResults = [];
      this.modalTotal = 0;
      this.modalVisible = "";
    },
    userSelect(data) {
      this.user = data._id;
      this.plannerName = data.username;
      this.mobileNo = data.mobileNo;
      this.userModalReset();
    },
  },
};
</script>
